import { TableCell, TableRow, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PreviewIcon from '@mui/icons-material/Preview';
import { Columns } from './PackageColumns';
import { useNavigate } from "react-router-dom";
import RouteLinks from '../../constants/RouteLinks';
import Package from '../../types/contracts/Responses/Package';

interface PackageTableItemProps {
    package: Package,
    deleteFunction: (id: string) => unknown
}

const PackageTableItem = (props: PackageTableItemProps) => {
    const navigate = useNavigate();

    const selectItem = (e: any) => {
        if (!window?.getSelection()?.isCollapsed) {
            return;
        }

        navigate(`${RouteLinks.PACKAGES}/${props.package.id}`)
    }

    const handleDelete = (e: any) => {
        e.stopPropagation();
        props.deleteFunction(props.package.id);
    }

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={props.package.id} onClick={(e) => { selectItem(e) }}>
            {Columns.map((column) => {
                const value = props.package[column.id];
                return (
                    <TableCell key={column.id} align={column.align}>
                        {column.format
                            ? column.format(value)
                            : value}
                    </TableCell>

                );
            })}

            <TableCell key="actions">
                <IconButton aria-label="preview package" component="span"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        window.open(`${process.env.REACT_APP_WEB_URL}packages/${props.package.id}`, "_blank", "noreferrer");
                    }}>
                    <PreviewIcon />
                </IconButton>
                <IconButton color="error" aria-label="delete package" component="span" onClick={(e: any) => { handleDelete(e) }}>
                    <DeleteForeverIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default PackageTableItem;
