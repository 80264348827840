import * as React from 'react';
import { Container, Box, Typography, TextField, Button, Grid, Autocomplete, FormControlLabel, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import RouteLinks from '../../constants/RouteLinks';
import AutoCompleteItem from '../../types/AutoCompleteItem';
import Package from '../../types/contracts/Responses/Package';
import debounce from 'lodash.debounce';
import CategoryList from '../../types/contracts/Responses/CategoryList';
import CategoryService from '../../services/CategoryService';


interface PackageFormProps {
    formName: string,
    travelPackage: Package,
    setTravelPackage: React.Dispatch<React.SetStateAction<Package>>,
    submitFunction: (travelPackage: Package) => unknown,
    extraFields?: JSX.Element
}

const PackageForm = (props: PackageFormProps) => {
    const travelPackage = props.travelPackage
    const setTravelPackage = props.setTravelPackage
    const defaultInputValue = travelPackage.category.name;
    const [cateogrySuggestions, setCategorySuggestions] = React.useState<AutoCompleteItem[]>([]);
    const [categorySearch, setCategorySearch] = React.useState<string>(defaultInputValue);
    const [categorySearchInput, setCategorySearchInput] = React.useState<string>(defaultInputValue);

    React.useEffect(() => {
        const getSuggestions = async (inputSearch: string, limit: number, offset: number) => {
            const list: CategoryList | null = await CategoryService.getAll(limit, offset, inputSearch);
            if (list != null) {
                setCategorySuggestions(list.categories.map((category) => ({ id: category.id, label: category.name })));
            }
        }

        getSuggestions(categorySearch, 10, 0);
    }, [categorySearch]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.submitFunction({ ...travelPackage });
    }

    const searchDelayed = React.useMemo(
        () => debounce((newValue: string) => setCategorySearch(newValue), 1000),
        []
    );

    const countryCodes = [
        "AF", "AX", "AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "AT", "AZ", "BH", "BS", "BD",
        "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BQ", "BA", "BW", "BV", "BR", "IO", "BN", "BG", "BF", "BI", "KH",
        "CM", "CA", "CV", "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CG", "CD", "CK", "CR", "CI", "HR", "CU",
        "CW", "CY", "CZ", "DK", "DJ", "DM", "DO", "EC", "EG", "SV", "GQ", "ER", "EE", "ET", "FK", "FO", "FJ", "FI", "FR",
        "GF", "PF", "TF", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY",
        "HT", "HM", "VA", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT", "JM", "JP", "JE", "JO",
        "KZ", "KE", "KI", "KP", "KR", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MK", "MG",
        "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM", "MD", "MC", "MN", "ME", "MS", "MA", "MZ",
        "MM", "NA", "NR", "NP", "NL", "NC", "NZ", "NI", "NE", "NG", "NU", "NF", "MP", "NO", "OM", "PK", "PW", "PS", "PA",
        "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR", "QA", "RE", "RO", "RU", "RW", "BL", "SH", "KN", "LC", "MF", "PM",
        "VC", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", "SX", "SK", "SI", "SB", "SO", "ZA", "GS", "SS", "ES",
        "LK", "SD", "SR", "SJ", "SZ", "SE", "CH", "SY", "TW", "TJ", "TZ", "TH", "TL", "TG", "TK", "TO", "TT", "TN", "TR",
        "TM", "TC", "TV", "UG", "UA", "AE", "GB", "US", "UM", "UY", "UZ", "VU", "VE", "VN", "VG", "VI", "WF", "EH", "YE",
        "ZM", "ZW"
    ];

    return (
        <Container component="main">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography component="h1" variant="h5">
                    {props.formName}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, minWidth: '50%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Pavadinimas"
                        name="name"
                        value={travelPackage.name}
                        onChange={(e) => { setTravelPackage({ ...travelPackage, name: e.target.value }) }}
                        autoFocus
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Kaina"
                        name="price"
                        type="number"
                        inputProps={{ min: 0, step: 0.1 }}
                        value={travelPackage.price}
                        onChange={(e) => { setTravelPackage({ ...travelPackage, price: parseFloat(e.target.value) }) }}
                        autoFocus
                    />

                    <Autocomplete
                        fullWidth
                        id="countryCode"
                        options={countryCodes}
                        renderInput={(params) => (
                            <TextField {...params} label="Šalis" required />
                        )}
                        onChange={(_event, newValue) => {
                            setTravelPackage({ ...travelPackage, countryCode: newValue ?? '' });
                        }}
                        value={travelPackage.countryCode}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="shortDescription"
                        label="Trumpas aprašymas"
                        name="shortDescription"
                        multiline
                        rows={2}
                        value={travelPackage.shortDescription}
                        onChange={(e) => { setTravelPackage({ ...travelPackage, shortDescription: e.target.value }) }}
                        autoFocus
                    />

                    <TextField
                        margin="normal"
                        fullWidth
                        id="description"
                        label="Pilnas aprašymas"
                        name="description"
                        multiline
                        rows={4}
                        value={travelPackage.description}
                        onChange={(e) => { setTravelPackage({ ...travelPackage, description: e.target.value }) }}
                        autoFocus
                    />

                    <TextField
                        margin="normal"
                        fullWidth
                        id="totalDays"
                        label="Dienų skaičius"
                        name="totalDays"
                        type="number"
                        value={travelPackage.totalDays}
                        onChange={(e) => { setTravelPackage({ ...travelPackage, totalDays: parseInt(e.target.value) }) }}
                        autoFocus
                    />

                    <Autocomplete
                        disablePortal
                        fullWidth
                        id="category"
                        options={cateogrySuggestions.map(suggestion => ({ id: suggestion.id, label: suggestion.label }))}
                        renderInput={(params) => <TextField {...params} label="Kategorija" required />}
                        onChange={(e: any, value) => {
                            setTravelPackage({ ...travelPackage, category: { id: value?.id ?? '', name: travelPackage.category.name } })
                            setCategorySearchInput(value?.label ?? '')
                        }}
                        inputValue={categorySearchInput}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason === "input" || reason === "clear") {
                                searchDelayed(newInputValue);
                                setCategorySearchInput(newInputValue)
                            }
                        }}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                    />

                    <TextField
                        margin="normal"
                        fullWidth
                        id="discount"
                        label="Nuolaida %"
                        name="discount"
                        type="number"
                        value={travelPackage.discount}
                        onChange={(e) => { setTravelPackage({ ...travelPackage, discount: parseInt(e.target.value) }) }}
                        autoFocus
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                value="active"
                                color="primary"
                                checked={travelPackage.active}
                                onChange={(e) => { setTravelPackage({ ...travelPackage, active: e.target.checked }) }}
                            />
                        }
                        label="Publikuoti"
                    />

                    {props.extraFields && (
                        <div>
                            {props.extraFields}
                        </div>
                    )}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Pateikti
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link to={RouteLinks.PACKAGES}>
                                Atgal
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default PackageForm;
