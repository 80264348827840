import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import CreateCategoryRequest from '../../types/contracts/Requests/CreateCategoryRequest';
import CategoryService from '../../services/CategoryService';
import RouteLinks from '../../constants/RouteLinks';
import CategoryForm from './CategoryForm';
import Category from "../../types/contracts/Responses/Category";


const CategoryCreateForm = () => {
    const navigate = useNavigate();

    const createCategory = async (request: CreateCategoryRequest) => {
        const category: Category | null = await CategoryService.create(request)
        if (category != null) {
            navigate(RouteLinks.CATEGORIES);
            toast.success("Kategorija buvo sėkmingai sukurta");
        }
    };

    const category: CreateCategoryRequest = {
        name: ''
    }

    return (
        <CategoryForm formName='Kategorijos kūrimas' submitFunction={createCategory} category={category} />
    );
}

export default CategoryCreateForm;
