import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Grid, Box } from '@mui/material';
import { Columns } from './PackageColumns';
import AddFloatActionButton from '../common/AddFloatActionButton';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import PackageTableItem from './PackageTableItem';
import RouteLinks from '../../constants/RouteLinks';
import Package from '../../types/contracts/Responses/Package';
import PackageService from '../../services/PackageService';
import PackageFilters from '../../types/contracts/Requests/PackageFilters';


const PackageTable = () => {
    const navigate = useNavigate();

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

    const [packages, setPackages] = React.useState<Package[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        const getItems = async (limit: number, offset: number) => {
            setDataLoaded(false);
            const filters: PackageFilters = {
                limit,
                offset
            };
            const list = await PackageService.getAll(filters);
            if (list != null) {
                setPackages(list.packages);
                setTotalCount(list.totalCount);
            }
            setDataLoaded(true);
        }

        getItems(rowsPerPage, page * rowsPerPage);
    }, [rowsPerPage, page]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteItem = async (id: string) => {
        const success = await PackageService.delete(id);
        if (success) {
            setPackages(packages.filter((item) => item.id !== id));
        }
    }

    const promptDeletion = async (id: string) => {
        confirmAlert({
            title: 'Paketo trynimas',
            message: 'Ar tikrai norite tęsti?',
            buttons: [
                {
                    label: 'Taip',
                    onClick: () => deleteItem(id)
                },
                {
                    label: 'Ne',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <Box sx={{ marginTop: 10 }}>
            <Grid
                container
                spacing={2}
                alignItems="center"
            >
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Paper sx={{ overflow: 'hidden', marginTop: 1 }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            Columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))

                                        }
                                        <TableCell
                                            key="btnDelete"
                                        >
                                            Veiksmai
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataLoaded ?
                                            packages.map((row) => <PackageTableItem key={row.id} package={row} deleteFunction={promptDeletion} />)
                                            :
                                            <TableRow>
                                                <TableCell colSpan={Columns.length + 1}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                        marginTop: 10,
                                                        marginBottom: 10
                                                    }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid >
            <AddFloatActionButton onClick={() => { navigate(`${RouteLinks.PACKAGES}/new`) }} />
        </Box>
    );
}

export default PackageTable;
