import httpClient from '../clients/HttpClient';

class AuthService {
    async login(email: string, password: string, recaptchaResponse: string | undefined) {
        if (process.env.REACT_APP_AUTH_URL === undefined || process.env.REACT_APP_API_URL === undefined) {
            throw new Error("AUTH_URL is not set");
        }

        httpClient.setBaseUrl(process.env.REACT_APP_AUTH_URL);        

        let response = await httpClient.post('/authenticate', {
            email,
            password,
            recaptchaResponse
        }, {}, false)

        httpClient.setBaseUrl(process.env.REACT_APP_API_URL);

        if (response.data && response.data.accessToken) {
            localStorage.setItem("authToken", response.data.accessToken);
            return true;
        }

        return false;
    }

    async logout() {
        this.removeToken();
    }

    removeToken() {
        localStorage.removeItem("authToken");
    }

    isLoggedIn(): boolean {
        return this.getToken() !== null;
    }

    getToken() {
        const authTokenJson = localStorage.getItem('authToken');

        return authTokenJson;
    }

    getAuthHeader() {
        const authToken = this.getToken();

        if (authToken) {
            return { Authorization: `Bearer ${authToken}` };
        }
    }
}

const newAuthService = new AuthService();
export default newAuthService;
