import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import React from "react";
import CategoryService from '../../services/CategoryService';
import RouteLinks from '../../constants/RouteLinks';
import Category from '../../types/contracts/Responses/Category';
import CategoryForm from './CategoryForm';
import CreateCategoryRequest from '../../types/contracts/Requests/CreateCategoryRequest';

const CategoryUpdateForm = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [category, setCategory] = React.useState<Category | null>(null);

    React.useEffect(() => {
        const getCategory = async (id: string) => {
            const category = await CategoryService.getById(id);
            setCategory(category);
        }

        if (id !== undefined)
            getCategory(id);
        else {
            navigate(RouteLinks.CATEGORIES);
        };
    }, [id, navigate]);

    const updateCategory = async (category: CreateCategoryRequest) => {
        const updatedCategory = await CategoryService.update(id!, category);
        if (updatedCategory != null) {
            navigate(RouteLinks.CATEGORIES);
            toast.success("Kategorija buvo sėkmingai atnaujinta.");
        }
    };

    return (
        <>
            {
                category ?
                    <CategoryForm formName='Kategorijos atnaujinimas' submitFunction={updateCategory} category={category} />
                    :
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 20
                    }}>
                        <CircularProgress />
                    </Box>
            }
        </>
    );
}

export default CategoryUpdateForm;
