import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import AuthService from '../services/AuthService';
import { useNavigate, Navigate } from "react-router-dom";
import RouteLinks from '../constants/RouteLinks';
import Copyright from './Copyright';

export default function SignIn() {
    const navigate = useNavigate();
    const recaptchaRef = React.useRef<ReCAPTCHA>(null);

    if (process.env.REACT_APP_RECAPTCHA_KEY == null) {
        throw new Error("reCAPTCHA key not set");
    }

    if (AuthService.isLoggedIn()) {
        return (
            <Navigate to={RouteLinks.HOME} />
        )
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.toString();
        const password = data.get('password')?.toString();
        if (email === undefined || email === '' || password === undefined || password === '') {
            toast.error("Email and password is required");
            return;
        }

        const recaptchaResponse = recaptchaRef.current?.getValue();
        if (recaptchaResponse == null || recaptchaResponse === "") {
            toast.error("CAPTCHA privalo būti užpildyta");
            return;
        }

        try {
            await AuthService.login(email, password, recaptchaResponse);
            if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate(RouteLinks.HOME, { replace: true });
            }
        }
        catch (e: any) {
            if (e.response?.data?.message) {
                toast.error(e.response.data.message);
            }
            else {
                toast.error("Could not sign in. Please try again later");
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Prisijungimas
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="El. paštas"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Slaptažodis"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? (() => { throw new Error("ReCaptcha site key not provided") })()}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Prisijungti
                    </Button>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
