import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import RouteLinks from '../../constants/RouteLinks';
import Package from "../../types/contracts/Responses/Package";
import PackageForm from "./PackageForm";
import PackageService from "../../services/PackageService";
import PackageMapper from "../../services/mappers/PackageMapper";
import { Button, Input, InputLabel, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React from "react";

const PackageCreateForm = () => {
    const navigate = useNavigate();

    const [travelPackage, setTravelPackage] = React.useState<Package>({
        id: '',
        price: 0,
        name: '',
        countryCode: '',
        shortDescription: '',
        category: {
            id: '',
            name: ''
        },
        active: false,
        thumbnailImageUrl: '',
        coverImageUrl: ''
    });

    const [thumbnailImageFile, setThumbnailImageFile] = React.useState<File | null>(null);
    const [coverImageFile, setCoverImageFile] = React.useState<File | null>(null);

    const createItem = async (request: Package) => {
        if (thumbnailImageFile == null || coverImageFile == null) {
            toast.error("Prašome įkelti paveiksliukus.")
            return;
        }

        const travelpackage: Package | null = await PackageService.create(PackageMapper.packageToCreatePackageRequest(request, thumbnailImageFile, coverImageFile))
        if (travelpackage != null) {
            navigate(RouteLinks.PACKAGES);
            toast.success("Paketas buvo sėkmingai sukurtas");
        }
    };

    const handleThumbnailImageFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setThumbnailImageFile(event.target.files[0]);
        }
    };

    const handleCoverImageFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setCoverImageFile(event.target.files[0]);
        }
    };

    return (
        <PackageForm formName='Paketo kūrimas' submitFunction={createItem} travelPackage={travelPackage} setTravelPackage={setTravelPackage} extraFields={
            <>
                <InputLabel htmlFor="thumbnail-upload">
                    <Button variant="contained" startIcon={<CloudUploadIcon />} component="span">
                        Įkelti mažą paveiksliuką
                    </Button>
                </InputLabel>
                <Input
                    id="thumbnail-upload"
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    onChange={handleThumbnailImageFileChange}
                    style={{ display: 'none' }}
                />
                <Typography variant="body1">
                    {thumbnailImageFile ? `Pasirinktas failas: ${thumbnailImageFile.name}` : 'Failas nepasirinktas'}
                </Typography>


                <InputLabel htmlFor="cover-upload">
                    <Button variant="contained" startIcon={<CloudUploadIcon />} component="span">
                        Įkelti didelį paveiksliuką
                    </Button>
                </InputLabel>
                <Input
                    id="cover-upload"
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    onChange={handleCoverImageFileChange}
                    style={{ display: 'none' }}
                />
                <Typography variant="body1">
                    {coverImageFile ? `Pasirinktas failas: ${coverImageFile.name}` : 'Failas nepasirinktas'}
                </Typography>
            </>
        } />
    );
}

export default PackageCreateForm;
