import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useLocation } from 'react-router-dom';
import RouteLinks from '../constants/RouteLinks';
import CategoryIcon from '@mui/icons-material/Category';
import CardTravelIcon from '@mui/icons-material/CardTravel';

const categories = [
    {
        id: 'Turinys',
        children: [
            {
                id: 'Kategorijos',
                url: RouteLinks.CATEGORIES,
                icon: <CategoryIcon />,
            },
            {
                id: 'Kelionių paketai',
                url: RouteLinks.PACKAGES,
                icon: <CardTravelIcon />,
            }
        ],
    }
];

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};

export default function Navigator(props: DrawerProps) {
    const { ...other } = props;
    const location = useLocation();

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
                    KelioniuABC
                </ListItem>
                <Link to={RouteLinks.HOME} className="link">
                    <ListItemButton selected={location.pathname === RouteLinks.HOME} sx={{ ...item, ...itemCategory }}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText>Pagrindinis</ListItemText>
                    </ListItemButton>
                </Link>
                {categories.map(({ id, children }) => (
                    <Box key={id} sx={{ bgcolor: '#101F33' }}>
                        <ListItem sx={{ py: 2, px: 3 }}>
                            <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                        </ListItem>
                        {children.map(({ id: childId, icon, url }) => (
                            <Link to={url} className="link" key={childId}>
                                <ListItem disablePadding>
                                    <ListItemButton selected={location.pathname === url} sx={item}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText>{childId}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        ))}
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                ))}
            </List>
        </Drawer>
    );
}
