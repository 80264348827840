import CreatePackageRequest from "../../types/contracts/Requests/CreatePackageRequest";
import UpdatePackageRequest from "../../types/contracts/Requests/UpdatePackageRequest";
import Package from "../../types/contracts/Responses/Package";

class PackageMapper {
    packageToUpdatePackageRequest(travelPackage: Package): UpdatePackageRequest
    {
        return {
            price: travelPackage.price,
            name: travelPackage.name,
            countryCode: travelPackage.countryCode,
            description: travelPackage.description,
            shortDescription: travelPackage.shortDescription,
            totalDays: travelPackage.totalDays,
            categoryId: travelPackage.category.id,
            discount: travelPackage.discount,
            active: travelPackage.active
        }
    }

    packageToCreatePackageRequest(travelPackage: Package, thumbnailImage: File, coverImage: File): CreatePackageRequest
    {
        return {
            price: travelPackage.price,
            name: travelPackage.name,
            countryCode: travelPackage.countryCode,
            description: travelPackage.description,
            shortDescription: travelPackage.shortDescription,
            totalDays: travelPackage.totalDays,
            categoryId: travelPackage.category.id,
            discount: travelPackage.discount,
            active: travelPackage.active,
            thumbnailImage,
            coverImage
        }
    }
}

const newPackageMapper = new PackageMapper();
export default newPackageMapper;
