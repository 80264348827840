import { AxiosRequestConfig, AxiosResponse } from 'axios'
import AuthService from '../services/AuthService'
import instance from './AxiosInstance';

instance.defaults.baseURL = process.env.REACT_APP_API_URL;
instance.defaults.headers.common['Content-Type'] = 'application/json';

class HTTPClient {
    setBaseUrl(url: string) {
        instance.defaults.baseURL = url;
    }

    async get(path: string, includeAuthHeader: boolean = true): Promise<AxiosResponse> {
        let headers = {};
        if (includeAuthHeader) {
            headers = { headers: AuthService.getAuthHeader() };
        }

        const response: AxiosResponse = await instance.get(path, headers);
        return response;
    }

    async post(path: string, body: any, headers: Record<string, string> = {}, includeAuthHeader: boolean = true): Promise<AxiosResponse> {
        if (includeAuthHeader) {
            headers = { ...headers, Authorization: `Bearer ${AuthService.getToken()}` };
        }

        const config: AxiosRequestConfig = { headers: headers }
        const response: AxiosResponse = await instance.post(path, body, config);

        return response;
    }

    async put(path: string, body: any, headers: Record<string, string> = {}, includeAuthHeader: boolean = true): Promise<AxiosResponse> {
        if (includeAuthHeader) {
            headers = { ...headers, Authorization: `Bearer ${AuthService.getToken()}` };
        }

        const config: AxiosRequestConfig = { headers: headers }
        const response: AxiosResponse = await instance.put(path, body, config);
        return response;
    }

    async delete(path: string, includeAuthHeader: boolean = true): Promise<AxiosResponse> {
        let headers = {};
        if (includeAuthHeader) {
            headers = { headers: AuthService.getAuthHeader() };
        }

        const response: AxiosResponse = await instance.delete(path, headers);
        return response;
    }

    async download(path: string, includeAuthHeader: boolean = true) {
        let headers: AxiosRequestConfig = {};
        if (includeAuthHeader) {
            headers = { headers: AuthService.getAuthHeader() };
        }

        headers.responseType = 'blob';
        const response: AxiosResponse = await instance.get(path, headers)

        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1].split(';')[0]);
        document.body.appendChild(link);
        link.click();
    }
}

const httpClient = new HTTPClient();
export default httpClient;
