import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import React from "react";
import RouteLinks from '../../constants/RouteLinks';
import PackageForm from './PackageForm';
import PackageService from '../../services/PackageService';
import Package from '../../types/contracts/Responses/Package';
import PackageMapper from '../../services/mappers/PackageMapper';
import { Container, Stack } from '@mui/system';
import { Button, Input, InputLabel } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const PackageUpdateForm = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [travelPackage, setTravelPackage] = React.useState<Package>(null!);

    React.useEffect(() => {
        const getItem = async (id: string) => {
            const item: Package | null = await PackageService.getById(id);
            if (item != null) {
                setTravelPackage(item);
            }
            else {
                navigate(RouteLinks.PACKAGES);
            }
        }

        if (id !== undefined)
            getItem(id);
        else {
            navigate(RouteLinks.PACKAGES);
        };
    }, [id, navigate]);

    const updateItem = async (travelPackage: Package) => {
        const updatedItem = await PackageService.update(id!, PackageMapper.packageToUpdatePackageRequest(travelPackage));
        if (updatedItem != null) {
            navigate(RouteLinks.PACKAGES);
            toast.success("Paketas buvo sėkmingai atnaujintas.");
        }
    };

    const handleThumbnailUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const updatedTravelPackage: Package | null = await PackageService.updateThumbnail(travelPackage.id, event.target.files[0]);
            if (updatedTravelPackage != null) {
                setTravelPackage({ ...travelPackage, thumbnailImageUrl: updatedTravelPackage.thumbnailImageUrl })
                toast.success("Paketas buvo sėkmingai atnaujintas.");
            }
        }
    };

    const handleCoverUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const updatedTravelPackage: Package | null = await PackageService.updateCover(travelPackage.id, event.target.files[0]);
            if (updatedTravelPackage != null) {
                setTravelPackage({ ...travelPackage, coverImageUrl: updatedTravelPackage.coverImageUrl })
                toast.success("Paketas buvo sėkmingai atnaujintas.");
            }
        }
    };

    return (
        <>
            {
                travelPackage ?
                    <>
                        <PackageForm formName='Paketo atnaujinimas' submitFunction={updateItem} travelPackage={travelPackage} setTravelPackage={setTravelPackage} />
                        <Container component="section">
                            <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
                                <InputLabel htmlFor="thumbnail-upload">
                                    <Button variant="contained" startIcon={<CloudUploadIcon />} component="span">
                                        Įkelti mažą paveiksliuką
                                    </Button>
                                </InputLabel>
                                <Input
                                    id="thumbnail-upload"
                                    type="file"
                                    inputProps={{ accept: 'image/*' }}
                                    onChange={handleThumbnailUpload}
                                    style={{ display: 'none' }}
                                />

                                {travelPackage.thumbnailImageUrl &&
                                    <img
                                        src={travelPackage.thumbnailImageUrl}
                                        style={{ maxWidth: "500px", maxHeight: "500px" }}
                                        alt="Mažas paveiksliukas"
                                    />
                                }
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
                                <InputLabel htmlFor="cover-upload">
                                    <Button variant="contained" startIcon={<CloudUploadIcon />} component="span">
                                        Įkelti didelį paveiksliuką
                                    </Button>
                                </InputLabel>
                                <Input
                                    id="cover-upload"
                                    type="file"
                                    inputProps={{ accept: 'image/*' }}
                                    onChange={handleCoverUpload}
                                    style={{ display: 'none' }}
                                />

                                {travelPackage.coverImageUrl &&
                                    <img
                                        src={travelPackage.coverImageUrl}
                                        style={{ maxWidth: "500px", maxHeight: "500px" }}
                                        alt="Didelis paveiksliukas"
                                    />
                                }
                            </Stack>
                        </Container>
                    </>
                    :
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 20
                    }}>
                        <CircularProgress />
                    </Box>
            }
        </>
    );
}

export default PackageUpdateForm;
