import { AxiosResponse } from 'axios';
import httpClient from '../clients/HttpClient';
import PackageList from '../types/contracts/Responses/PackageList';
import { toast } from 'react-toastify';
import Package from '../types/contracts/Responses/Package';
import UpdatePackageRequest from '../types/contracts/Requests/UpdatePackageRequest';
import CreatePackageRequest from '../types/contracts/Requests/CreatePackageRequest';
import PackageFilters from '../types/contracts/Requests/PackageFilters';

class PackageService {
    baseUri: string = "/v1/Packages";

    constructFilterQueryParams = (filters: PackageFilters): string => {
        const queryParams = new URLSearchParams();

        for (const [key, value] of Object.entries(filters)) {
            if (value !== undefined && value !== null) {
                if (Array.isArray(value)) {
                    value.forEach((item) => queryParams.append(key, item));
                } else {
                    queryParams.append(key, String(value));
                }
            }
        }

        return queryParams.toString();
    };

    displayError = (e: any) => {
        if (e.response?.data?.message && e.response.status !== 500) {
            const data = e.response.data;
            let errorMessage = `${data.message}\n`;

            for (const key in data.details) {
              if (data.details.hasOwnProperty(key)) {
                const fieldErrors = data.details[key];
                errorMessage += `${key}: ${fieldErrors.join(', ')}\n`;
              }
            }

            toast.error(errorMessage);
        }
        else {
            toast.error("Serverio klaida. Prašome bandyti vėliau.");
        }
    }

    async getAll(filters: PackageFilters): Promise<PackageList | null> {
        try {
            const query: string = this.constructFilterQueryParams(filters);
            const response: AxiosResponse = await httpClient.get(`${this.baseUri}?${query}`);
            const list: PackageList = {
                packages: response.data,
                totalCount: parseInt(response.headers['total-count'])
            }

            return list;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    async getById(id: string): Promise<Package | null> {
        try {
            const response: AxiosResponse = await httpClient.get(`${this.baseUri}/${id}`);
            return response.data;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    async delete(id: string): Promise<boolean> {
        try {
            await httpClient.delete(`${this.baseUri}/${id}`);
            return true;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return false;
    }

    async create(request: CreatePackageRequest): Promise<Package | null> {
        try {
            const formData = new FormData();
            Object.entries(request).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                    formData.append(capitalizedKey, value);
                }
            });

            const response: AxiosResponse = await httpClient.post(this.baseUri, formData, { 'Content-Type': 'multipart/form-data' });
            return response.data;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    async update(id: string, request: UpdatePackageRequest): Promise<Package | null> {
        try {
            const response: AxiosResponse = await httpClient.put(`${this.baseUri}/${id}`, request);
            return response.data;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    async updateCover(id: string, image: File): Promise<Package | null> {
        try {
            const formData = new FormData();
            formData.append("file", image);

            const response: AxiosResponse = await httpClient.put(`${this.baseUri}/${id}/cover`, formData, { 'Content-Type': 'multipart/form-data' });
            return response.data;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    async updateThumbnail(id: string, image: File): Promise<Package | null> {
        try {
            const formData = new FormData();
            formData.append("file", image);

            const response: AxiosResponse = await httpClient.put(`${this.baseUri}/${id}/thumbnail`, formData, { 'Content-Type': 'multipart/form-data' });
            return response.data;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }
}

const newPackageService = new PackageService();
export default newPackageService;
