import axios, { AxiosError } from "axios";
import RouteLinks from "../constants/RouteLinks";
import AuthService from "../services/AuthService";

const instance = axios.create();

instance.interceptors.response.use(function (response) {
    return response;
}, function (error : AxiosError) {
    if (error.response?.status === 401) {
        if (AuthService.isLoggedIn()) {
            AuthService.logout();
            window.location.replace(RouteLinks.LOGIN);
        }
    }
    
    return Promise.reject(error);
});

export default instance
