export interface CategoryColumn {
    id: 'id' | 'name';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly CategoryColumn[] = [
    { id: 'id', label: 'Id' },
    { id: 'name', label: 'Pavadinimas' },
];

export const Columns = columns;
