import { Navigate, Outlet } from 'react-router-dom'
import RouteLinks from '../constants/RouteLinks';
import AuthService from '../services/AuthService';

const PrivateRoutes = () => {
    return (
        AuthService.isLoggedIn() ? <Outlet /> : <Navigate to={RouteLinks.LOGIN} />
    )
}

export default PrivateRoutes;
