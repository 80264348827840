import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';

interface AddFloatActionButtonProps {
    onClick: () => unknown
}

const AddFloatActionButton = (props: AddFloatActionButtonProps) => {
    return (
        <Fab
            color="primary"
            aria-label="add"
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2)
            }}
            onClick={props.onClick}
        >
            <AddIcon />
        </Fab>
    );
}

export default AddFloatActionButton;
