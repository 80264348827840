import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import RouteLinks from './constants/RouteLinks';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Login from './components/Login';
import PrivateRoutes from './util/PrivateRoutes';
import BaseLayout from './components/BaseLayout';
import Dashboard from './components/Dashboard';
import CategoryTable from './components/Categories/CategoryTable';
import CategoryUpdateForm from './components/Categories/CategoryUpdateForm';
import CategoryCreateForm from './components/Categories/CategoryCreateForm';
import PackageTable from './components/Packages/PackageTable';
import PackageCreateForm from './components/Packages/PackageCreateForm';
import PackageUpdateForm from './components/Packages/PackageUpdateForm';


const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path={RouteLinks.LOGIN} element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path={RouteLinks.HOME} element={<BaseLayout content={<Dashboard />} />} />
            <Route path={RouteLinks.CATEGORIES} element={<BaseLayout content={<CategoryTable />} />} />
            <Route path={`${RouteLinks.CATEGORIES}/new`} element={<BaseLayout content={<CategoryCreateForm />} />} />
            <Route path={`${RouteLinks.CATEGORIES}/:id`} element={<BaseLayout content={<CategoryUpdateForm />} />} />
            <Route path={RouteLinks.PACKAGES} element={<BaseLayout content={<PackageTable />} />} />
            <Route path={`${RouteLinks.PACKAGES}/new`} element={<BaseLayout content={<PackageCreateForm />} />} />
            <Route path={`${RouteLinks.PACKAGES}/:id`} element={<BaseLayout content={<PackageUpdateForm />} />} />
          </Route>
          <Route path="*" element={<p>Page not found: 404!</p>} />
        </Routes>
      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  );
}

export default App;
