import * as React from 'react';
import { Container, CssBaseline, Box, Typography, TextField, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import CreateCategoryRequest from '../../types/contracts/Requests/CreateCategoryRequest';
import RouteLinks from '../../constants/RouteLinks';

interface CategoryFormData {
    name: string
}

interface CategoryFormProps {
    formName: string,
    category: CreateCategoryRequest
    submitFunction: (category: CreateCategoryRequest) => unknown
}

const CategoryForm = (props: CategoryFormProps) => {
    const [category, setCategory] = React.useState<CategoryFormData>(
        {
            name: props.category.name
        }
    );

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.submitFunction({ ...category });
    }

    return (
        <Container component="main">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography component="h1" variant="h5">
                    {props.formName}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, minWidth: '50%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Pavadinimas"
                        name="name"
                        value={category.name}
                        onChange={(e) => { setCategory({ ...category, name: e.target.value }) }}
                        autoFocus
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Pateikti
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link to={RouteLinks.CATEGORIES}>
                                Atgal
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default CategoryForm;
