import { TableCell, TableRow, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Columns } from './CategoryColumns';
import { useNavigate } from "react-router-dom";
import Category from '../../types/contracts/Responses/Category';
import RouteLinks from '../../constants/RouteLinks';

interface CategoryTableItemProps {
    category: Category,
    deleteFunction: (id: string) => unknown
}

const CategoryTableItem = (props: CategoryTableItemProps) => {
    const navigate = useNavigate();

    const selectItem = (e: any) => {
        if (!window?.getSelection()?.isCollapsed) {
            return;
        }

        navigate(`${RouteLinks.CATEGORIES}/${props.category.id}`)
    }

    const handleDelete = (e: any) => {
        e.stopPropagation();
        props.deleteFunction(props.category.id);
    }

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={props.category.id} onClick={(e) => { selectItem(e) }}>
            {Columns.map((column) => {
                const value = props.category[column.id];
                return (
                    <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                    </TableCell>

                );
            })}

            <TableCell key="deleteAction">
                <IconButton color="error" aria-label="delete category" component="span" onClick={(e: any) => { handleDelete(e) }}>
                    <DeleteForeverIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default CategoryTableItem;
