import { Box, Typography } from '@mui/material';

export default function Dashboard() {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 10,
      marginBottom: 10
    }}>
      <Typography variant='h3'>
        Sveiki!
      </Typography>
    </Box>

  );
}
