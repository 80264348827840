export interface PackageColumn {
    id: 'id' | 'name' | 'countryCode' | 'price' | 'discount' | 'active';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any) => string;
}

const columns: readonly PackageColumn[] = [
    { id: 'id', label: 'Id' },
    { id: 'name', label: 'Pavadinimas' },
    { id: 'countryCode', label: 'Šalis' },
    { id: 'price', label: 'Kaina €' },
    { id: 'discount', label: 'Nuolaida %' },
    { id: 'active', label: 'Publikuota', format: (value: boolean) => value ? "Taip" : "Ne" },
];

export const Columns = columns;
